
.gb_Ob {
    border: none;
    vertical-align: top;
    height: 50px;
    width: 48px;
}
.gb_sb {
    -webkit-border-radius: 50%;
    border-radius: 50%;
    overflow: hidden;
    -webkit-transform: translateZ(0);
}
.gb_Hb {
    display: inline-block;
    margin: 10px 0px 0px 15px;
}

.gb_Pb,.gb_Qb  {
    color: #686f7a !important;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 130px;
    
}
.gb_Pb{
  font-size: 11px;
}
.gb_Qb{
  font-size: 10px;
}
.nopicadjest{
    position: absolute;
    left:50px;
}
.user-avatar-wrapper{
    position: relative;
    /* width: 100%; */
    height: 100%; 
    /* min-width:  70px; */
    min-height:  50px;
    padding-left: 5px;
}
.user-avatar {
    display: inline-block;
    position: absolute;
    top: 0; 
    height: 50px;
    width: 50px;
    background: #ffffff;
    border-style: solid;
    border-width: 1px;
    color: #686f7a;
    font-size: 15px;
    border-radius: 50%;
}
  
.user-avatar__inner {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%; 
    font-size: 15px;
}

.user-avatar--initials { 
    border-style: solid;
    border-width: 1px;
    color: #686f7a;
    font-size: 15px;
}
.user-initials{
    display: inline-block;
  
    width: 100%;
    text-align: center;
    top: 9px;
    position: relative;
    line-height: 32px;
    font-weight: 500;  
}